import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';

import Activities from 'components/AboutProject/Activities';
import ProjectHeader from 'components/AboutProject/ProjectHeader';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import SEO from 'components/SEO';

const ProjectActivities = (): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      activities: allContentfulProject(filter: {title: {eq: "Działania"}}) {
        edges {
          node {
            title
            description {
              description
            }
          }
        }
      }
    }
  `);


  const { title, description } = data.activities.edges[0].node;

  return (
    <Layout>
      <SEO title="O działaniach" />
      <ProjectHeader title={description.description} category={title} />
      <Activities />
      <Footer />
    </Layout>
  );
};

export default ProjectActivities;