import React from 'react';

type Props = {
  category: string;
  title: string;
}

const index = (props: Props): JSX.Element => {

  return (
    <div>
      <div className="project-header">
        <div className="project-header__cat">
          {`HOME / O PROJEKCIE / ${props.category}`}
        </div>
        <div className="project-header__title">
          {props.title}
        </div>
      </div>
    </div>
  );
};

export default index;