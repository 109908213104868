import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Moment from 'react-moment';

import { ContentfulEvent } from 'models/contentfulModels';

import ProjectBody from '../ProjectBody';
import 'moment/locale/pl';

const Activities = (): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      events: allContentfulEvents(sort: {fields: date, order: DESC}) {
        nodes {
          id
          title
          type
          link
          date
          tileImage {
            fluid (maxWidth: 280) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  `);

  return (
    <ProjectBody>
      <div className="project-content">
        <h1>O PROJEKCIE</h1>
        <p>
          Tworzymy dla Was nową przestrzeń do rozmowy o nauce. Prowadzimy kampanię popularyzującą i edukacyjną
          w dziedzinie filozofii nauki i umiejętności myślenia krytycznego. “Myśl jak naukowiec” jest inicjatywą,
          dzięki której chcemy promować edukację i nowe formy konstruktywnej dyskusji, poprzez autorski koncept
          grafów argumentacyjnych. Za ich pomocą postaramy się pobudzić ciekawość naukową, przy równoczesnym 
          nacisku na aktywizację uczestników na naszych profilach w social mediach, a także poprzez 
          przygotowane przez nas podcasty i artykuły.
        </p>
        <p> 
          W ramach projektu organizujemy także cykliczne wykłady i spotkania popularnonaukowe. Chcąc dotrzeć do 
          jak najszerszej publiczności, zapraszamy ciekawych prelegentów i prelegentki, otwierając pole do otwartej
          dyskusji o wyzwaniach naszej naukowej rzeczywistości.
        </p> 
        <p>
          W ramach naszego projektu planujemy także przeprowadzenie cyklu warsztatów o tematyce naukoznawczej dla
          szkół/uczelni. Celem programu szkoleń jest rozwój umiejętności, tj. krytycznego myślenia, przeprowadzania
          złożonych rozumowań, myślenia naukowego, argumentowania i dostrzegania nieoczywistych zależności wśród 
          kadry akademickiej i szkolnej oraz samych uczniów.
        </p>
        <h1>ARCHIWUM WYDARZEŃ</h1>
        <div className="project-content__events">
          {data.events.nodes.map((e: ContentfulEvent) => 
            <a href={e.link} key={e.date} className="materials__tile materials__tile--small">
              <Img fluid={e.tileImage.fluid} />
              <h1>{e.type}</h1>
              <h2>{e.title}</h2>
              <h1><Moment locale="pl" format="DD MMMM YYYY">{e.date}</Moment></h1>
            </a>  
          )}
        </div>
      </div>
    </ProjectBody>
  );
};

export default Activities;
